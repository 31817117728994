import React from 'react'
import {graphql} from 'gatsby'
import Layout from "../layouts/layout";

const TextPage = ({
  data: {
    gcms: { pagina },
  },
}) => (
  <Layout pageTitle={pagina.titel} pageType='text' heroImage={pagina.hero.url} overlay={pagina.hero.overlay}>
    <p className="text__intro">{pagina.intro}</p>
    <div className="text__html" dangerouslySetInnerHTML={{__html: pagina.tekst.html}}></div>
  </Layout>
);

export const pageQuery = graphql`
  query PageQuery($id: ID!) {
    gcms {
      pagina(where: {id: $id }) {
        titel
        subtitel
        intro
        tekst {
          html
        }
        hero {
          url
          overlay
        }
      }
    }
  }
`;

export default TextPage
